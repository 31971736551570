@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Spinnaker', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:40px;
	letter-spacing:0.8px;
	text-transform:inherit;
	font-family: 'Spinnaker', sans-serif;
	font-weight:400;
	color:#000;
}
h2 {
	font-size:16px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:20px;
	font-family: 'Spinnaker', sans-serif;
}
h3 {
	font-size:16px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'Spinnaker', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Spinnaker', sans-serif;
}
h6 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Spinnaker', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Spinnaker', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 14px;
	color: #fff;
	font-family: 'Spinnaker', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#333;
	box-shadow:0 0 0 1px #e5e5e5;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'Spinnaker', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #fff;
	font-size: 20px;
	font-family: 'Spinnaker', sans-serif;
}
.links > li > a:hover {
	background-color: #2652BF;;
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: transparent;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #000;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #2652BF;
}
#nav .nav-panel--dropdown a {
	color: #000;
	font-size: 20px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 20px;
}
#nav .nav-panel--dropdown a:hover {
	color: #000;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #fff;
}
#nav .nav-item--home .fa-home {
	color: #fff !important;
	padding: 0.3rem;

}
#nav .nav-item--home {
       display: none; 
}
#root-wrapper a:hover span.fa:before {
	color: #fff;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #fff;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #000;
	border-right-color: #000;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #C1272D !important;
	padding: 0;
	border-radius: 5px;
	max-height: 45px;
	font-size: 18px;
	margin-top: 10px;
    color: #fff;
}
#mini-cart .actions button.button span span:hover {
	background-color: #2652BF !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 14px;
	letter-spacing: 2px;
	text-transform: capitalize;
    color: #000;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 40px;
	color: #000;
	letter-spacing: 0.8px;
	text-transform: inherit;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #C1272D;
	border-radius: 5px;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Spinnaker', sans-serif;
	font-size: 18px;
}
button.button span {
	font-size: 18px;
}
button.button span span {
	padding: 0 10px !important;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #2652BF !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 16px;
	color: #000; font-weight: 600;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
.footer-container, .footer-top-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #2652BF;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #333;
	color: #999;
    display: none;
}


/************************** MICS **************************/
#footer .section.clearer {
     width:100%;
}
#footer a, #footer p, #footer span {
     color:#fff;
     font-size:18px;
}
.collapsible h6.block-title.heading {
     text-transform:inherit;
     color:#fff;
     margin-bottom:8px;
     font-size:26px;
}
.collapsible ul.bullet li {
     background:none;
     padding-left:0;
     margin-bottom:8px;
}
.collapsible ul.bullet li:hover a {
     border-bottom:1px solid #000;
}
@media only screen and (min-width:768px) {
     .footer-links-col .item-default {
         display:grid;
         grid-template-columns:auto auto auto;
         justify-content:space-between;
    }
     .footer-links-col .item-default > div {
         width:100%;
    }
}
@media only screen and (min-width:768px) {
     .footer-social {
         display:flex;
         justify-content:space-between;
    }
}
#scroll-to-top {
     display:none !important;
}
.footer .collapsible .opener:hover {
     background-color:#C1272D;
}
#footer .fa-facebook:before {
     color:#2652BF;
     font-size:22px
}
#footer .fa-facebook {
     background-color:#fff;
     padding:5px 9px;
     border-radius:30px;
}
@media only screen and (max-width:959px) {
     .collapsible .block-title {
         padding-right:0;
    }
     .collapsible h6.block-title.heading {
         font-size:22px;
    }
     #footer a, #footer p, #footer span {
         color:#fff;
         font-size:16px;
    }
     .footer-links-col {
         overflow:hidden;
    }
}
@media only screen and (max-width:767px) {
     .footer-links-col {
         margin-bottom:30px;
    }
}

/*Header*/
.page-content ul, #product-tabs .std ul, .product-shop.module-product-details ul{
     list-style:disc;
     margin-left:25px;
}
.header-top-container {
     background-color:#2652BF ;
     box-shadow:none;
}
.header-top-container .module-header-multi{
     margin-left:0;
     margin-right:0;
     width:100%;
}
/*Align the text on the top header*/
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a{
     line-height:2em;
}
.top-header-container .module-header-multi p, .header-txt {
     margin-bottom:0 !important;
     line-height:2em;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.top-header-container .item.item-user-links ul.links > li:last-child a{
     padding-right:0;
}
.top-header-container .links>li>a, .header-txt {
     font-size:16px;
     font-weight:700;
}
.userSwitch a, .welcome-msg, .userSwitch a:hover, .links>li>a{
     color:#fff !important;
}
.desktop-top-header {
     padding:20px 0;
}
.desktop-top-header .header-top-container{
     background-color:#fff;
}
.desktop-top-header .tagline {
     color:#F12A18;
     font-family:'Playball', cursive;
     font-size:24px;
     font-weight:400;
     margin-bottom:0;
     line-height:normal;
}
/*Set all fa icons to be normal style*/
.std i {
     font-style:normal;
}
.desktop-top-header .grid-container {
     display:flex;
     justify-content:space-between;
}
.desktop-top-header .grid-container > div {
     margin:auto 0;
}
@media only screen and (min-width:1600px) {
     .desktop-top-header .logo-column {
         width:18%;
    }
     .desktop-top-header .grid-container > div:nth-child(2) {
         width:50%;
    }
}
/*Cart*/
.fa.fa-shopping-cart {
     float:right;
     left:inherit !important;
     margin-left:0;
     top:2px !important;
     font-size:24px !important;
}
#mini-cart .feature-icon-hover > a{
     display:inline-flex;
}
#mini-cart .feature-icon-hover > a > span {
     padding:0 !important;
}
#mini-cart .feature-icon-hover > a > span, #mini-cart .feature-icon-hover > a > .empty {
     order:1;
}
#mini-cart .feature-icon-hover > a > .empty span.price {
     margin-top:0;
}
.cart-total {
     display:inline-block;
     border-radius:50%;
     color:#fff !important;
     background-color:rgb(38, 82, 191);
     font-weight:700;
     font-size:12px !important;
     height:20px !important;
     width:20px;
     line-height:20px !important;
     text-align:center;
     position:relative;
     top:-8px;
}
.desktop-top-header .cart-column .feature-icon-hover {
     padding-right:0;
}
.desktop-top-header .cart-column .feature-icon-hover > a {
     float:right;
}
.userSwitch a {
     text-indent:-9999px;
     position:absolute;
}
 .userSwitch a::after {
     content:"(Switch Bill-to Company)";
     text-indent:0;
     float:left;
}

/*Menu*/
.desktop-category-column .module-category-menu .nav-item.nav-item--home {
     display:none;
}
.desktop-category-column .nav-regular li.level0>a>span {
     color:#fff;
     font-size:20px;
}
.desktop-category-column .nav-regular .nav-item.level0:hover > a, .desktop-category-column .nav-regular .nav-item.level0:hover > a > span {
     color:#fff;
}
.desktop-category-column .nav-regular .nav-panel li.classic {
     margin-bottom:5px;
}
.desktop-category-column .nav-regular li.level0>a {
     text-transform:inherit;
}
#nav .nav-item.level0.parent>a .caret {
     border-top-color:#fff;
}
@media only screen and (min-width:1280px) {
     .desktop-category-column #nav {
         display:flex;
         justify-content:space-between;
         margin-left:0;
    }
}
/*Hide-below-960/hide-above-960 doesn't work in all cases, so add the code but not for the mini cart*/
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}
/*Change the account menu icon for mobile/tablet*/
.fa-vcard:before{
     content:"" !important;
}
.fa-vcard:after{
     content:"\f007";
     color:#fff;
}
/*Inline search results*/
 #inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:160%;
         right:0;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68.5%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (max-width:768px) {
     #inline-search-results {
         width:250px;
         left:-50px !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
         min-height:236px !important;
    }
    #inline-search-results li div, #inline-search-results li span {
         font-size:12px;
    }
}
/*Tablet Header*/
@media only screen and (min-width:768px) and (max-width:1259px) {
     #header {
         border-bottom:1px solid #2652BF ;
    }
     .logo img {
         max-width:150px;
    }
     .desktop-top-header .cart-column {
         width:25%;
    }
     .desktop-top-header .grid-container > div:nth-child(2) {
         width:50%;
    }
     .desktop-top-header h1 {
         font-size:32px;
    }
     .desktop-top-header, .desktop-top-header .cart-column, .desktop-top-header .cart-column .module-header-multi {
         overflow:unset;
    }
    .desktop-top-header .grid-container {
        display:inherit;
    }
    .desktop-top-header .search-column {
        margin-top:15px !important;
    }
    .desktop-top-header .search-column .form-search {
        width:75%;
        margin: 0 auto;
    }
    .desktop-top-header .cart-column {
        margin-top:2%;
    }
    
}
/*Mobile Header*/
@media only screen and (max-width:767px) {
     .desktop-top-header {
         padding:5px 0;
    }
     .logo img {
         max-width:130px;
    }
     .desktop-top-header h1 {
         font-size:18px;
    }
     .desktop-top-header .tagline {
         font-size:14px;
    }
     .desktop-top-header .logo-column {
         width:auto !important;
         margin-right:2% !important;
    }
     .desktop-top-header, .mobile-header {
         margin-bottom:0 !important;
    }
     .mobile-header .grid-full.module-header-multi {
         display:flex;
         justify-content:center;
    }
     .mobile-header .grid-full.module-header-multi > div{
         margin:auto;
    }
     .mobile-header .fa.fa-bars, .mobile-header .fa.fa-vcard:after, .fa-shopping-cart {
         color:#fff !important;
         font-size:24px !important;
    }
     .mobile-header .cart-total, .mobile-header .empty {
         display:none;
    }
     .mobile-header, .mobile-header .grid-column-wrapper {
         overflow:unset;
    }
     /*Search on mobile*/
     .mobile-header div.form-search {
         width:94%;
         margin:0 auto;
    }
     .mobile-header .module-search .form-search .input-text {
         font-size:14px !important;
         height:30px;
         line-height:30px;
         border-radius:5px;
         border:1px solid #000;
    }
     .mobile-header .module-search .button-search .fa {
         color:#000 !important;
         padding:7px !important;
    }
     .top-header-container {
         margin-bottom:0;
    }
     .top-header-container .item.item-switch-customer {
         float:left;
    }
     .top-header-container .item.item-switch-customer span, .top-header-container .item.item-switch-customer a:after {
         font-size:12px;
         line-height:24px;
    }
}
/*category pages*/
.product-search-container {
     width:100%;
     margin-left:0;
     margin-right:0;
}
.breadcrumbs li span.breadcrumb-separator {
     margin:10px 4px 0;
}
.product-listing .page-title.category-title h1 {
     padding-top:0;
}
.product-listing .module-search.search-wrapper {
     padding-top:10px;
}
.sidebar-wrapper.module-category-filters .block-title span {
     text-transform:Capitalize;
}
.sidebar-wrapper.module-category-filters .block-title, .accordion-style1 li a {
     border:none;
}
.product-listing {
     margin-top:0 !important;
}
.cat-search-column input.input-text, .cat-search-column input.input-text:focus, .cat-search-column input.input-text:hover, .desktop-top-header .search-column input.input-text, .desktop-top-header .search-column input.input-text:focus, .desktop-top-header .search-column input.input-text:hover{
     border-color:#000;
     color:#000 !important;
     font-family:'Spinnaker', sans-serif;
     font-size:16px;
}
.cat-search-column input.input-text::placeholder, .desktop-top-header .search-column input.input-text::placeholder {
     color:#000 !important;
     font-size:16px;
}
.cat-search-column .module-search .form-search .button-search .fa, .desktop-top-header .search-column .module-search .form-search .button-search .fa{
     font-size:20px !important;
     color:#000 !important;
     padding:7px !important;
}
.product-listing h2.product-name{
     margin-bottom:10px;
}
.product-listing .price-box span {
     font-weight:700;
}
.pager {
     border:none;
     margin:0;
     padding:0;
}
.pager .pages li a{
     background-color:#5B87D9;
     color:#fff;
}
.pager .pages li.current, .pager .pages li a:hover {
     background-color:#fff;
     color:#000;
}
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
}
@media only screen and (max-width:767px) {
     .toolbar-section p, .toolbar-section a, .toolbar-section strong, .toolbar-section label, .toolbar-section li, .toolbar-section .per-page{
         font-size:14px;
    }
     .toolbar-section select {
         height:26px;
         padding:3px;
    }
     .pager .pages li a{
         height:25px;
         line-height:25px;
    }
}
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
.category-products-grid .item {
     border:none;
     margin-bottom:70px;
}
.product-listing .qty-box {
     height:40px;
     border:1px solid #C1272D;
     font-family:'Spinnaker', sans-serif;
}
.products-line .module-category-product-listing .add-cart-wrapper .qty-wrapper input.qty {
     width:3em!important;
}
.category-products-grid .item .module-category-product-listing__productDesc {
     margin-top:10px;
     text-align:left;
}
.category-products-grid .item .module-category-product-listing__price {
     text-align:left;
}
.category-products-grid .add-cart-wrapper button:last-child {
     margin-top:0;
}
@media only screen and (min-width:768px) {
     .category-products-grid .actions.add-cart-wrapper {
         display:flex;
         justify-content:start;
         padding-left:5%;
    }
     .category-products-grid .actions.add-cart-wrapper > div.qty-wrapper {
         margin-right:5%;
    }
     .category-products-grid .actions.add-cart-wrapper .btn-cart span span {
         padding:0 10px !important;
    }
}
.toolbar-section .module-category-misc{
     display:flex;
     justify-content:end;
}
.toolbar-section .module-category-misc > div {
     margin:auto 0;
}
@media only screen and (max-width:767px) {
     .toolbar-section .module-category-misc{
         justify-content:space-around;
         flex-wrap:wrap-reverse;
    }
     .category-products-grid .item .item-code-label {
         display:none;
    }
     .category-products-grid .actions.add-cart-wrapper {
         display:flex;
         justify-content:space-around;
    }
}

@media only screen and (min-width:1366px) {
     .toolbar-section .module-category-misc > div {
         margin:auto;
    }
     .toolbar-section .module-category-misc > div:last-child {
         margin-right:0;
    }
}
.product-listing .add-to-links span.icon-hover:hover {
     background-color:#fff ;
}
.link-wishlist.remove-from-wishlist .fa-star:before {
     color:#FF7810 !important;
}
/*Quick View*/
.quickviewbutton {
     background-color:transparent !important;
     border-radius:5px;
     left:5%;
}
.quickviewbutton span {
     font-size:16px !important;
}
.quickviewbutton span span {
     height:35px !important;
     line-height:35px !important;
     padding:0 14px !important;
}
@media only screen and (max-width:767px) {
     .quickviewbutton {
         display:none;
    }
}
/* Quick View Dialog*/
#quick-view-modal .modal-footer {
     border-top:none;
}
.quick-view-modal-dialog .product-view .btn-cart span span {
     padding:0 20px !important;
}
.quick-view-modal-dialog .button.pull-right {
     color:#000;
}
/*Line View*/
.category-products-listing{
     margin-top:40px;
}
.category-products-listing .products-line__labels{
     margin-bottom:40px;
}
@media only screen and (min-width:768px) and (max-width:1199px) {
     .module-category-product-listing .products-line__labels h2.product-name a, .module-category-product-listing .products-line__labels .item-code a, .module-category-product-listing .products-line__labels .price-box span{
         font-size:14px;
    }
}
@media only screen and (min-width:960px) {
     .module-category-product-listing .products-line__labels ul {
         display:flex;
    }
     .module-category-product-listing .products-line__labels .price-box p {
         margin-bottom:2px !important;
    }
     .module-category-product-listing .products-line__labels .btn-cart{
         margin-top:0 !important;
    }
}
@media only screen and (max-width:767px) {
     .module-category-product-listing .products-line__labels .price-box {
         margin-top:10px;
    }
     .module-category-product-listing .products-line__labels .price-box p {
         margin-bottom:2px !important;
    }
     .module-category-product-listing .products-line__labels .btn-cart{
         margin-top:0 !important;
    }
     .product-listing button.button span span {
         padding:0 !important;
    }
     .product-listing .add-cart-wrapper button span span {
         width:120px;
         max-width:120px;
    }
     .category-products-grid .item .qty-box {
         height:32px;
    }
     .product-listing h2.product-name a, .product-listing .item-code a, .product-listing .item-code-label, .product-listing .price-box span {
         font-size:13px !important;
    }
     button.button span {
         font-size:16px;
    }
}
@media only screen and (min-width:1400px) {
     button.button span {
         font-size:20px;
    }
}
/**/
.progress, div[class^=strength_meter_passwd] {
     top:25px !important;
}
.new-users button.button span, .registered-users button.button span {
     background-color:#2652BF;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide {
     margin-bottom:40px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu {
     display:flex;
     float:left;
     width:100%;
     background-color:#fff;
     padding-left:0 !important;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li {
     margin:0 auto;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li > a{
     padding-left:0;
     font-weight:bold;
     font-size:22px;
     color:#000 ;
}
@media only screen and (max-width:1535px) {
     .account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li > a{
         font-size:18px;
    }
}
@media only screen and (max-width:1259px) {
     .account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li > a{
         font-size:14px;
    }
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover > a {
     border-bottom:1px solid #2652BF ;
}
 .account-view .block-content.mm-menu ul.xm-mega-menu > li {
     width:100%;
}
#order-table, #transhistory-table, #address-book-table{
     margin-top:20px;
}
#my-orders-table div.product-name {
     font-size:16px;
}
.buttons-set.module-order-details-actions {
     text-align:end;
}
.buttons-set.module-order-details-actions .fa-angle-double-left:before {
     content:" " !important;
}
.order-info-box h2 {
     font-size:28px !important;
}
.order-info-box .box-content {
     margin-top:25px;
}
@media only screen and (min-width:960px) {
     .order-info-box.module-order-details-billing, .order-info-box.module-order-details-shipping{
         min-height:278px;
    }
     .col-1.addresses-primary .item, .col-2.addresses-primary .item{
         min-height:300px;
    }
     .module-order-details-misc > div {
         padding-left:10px;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .billing-module, .shipping-module, .col-1.addresses-primary, .col-2.addresses-primary {
         width:99%;
    }
     .col-2.addresses-primary {
         float:left;
    }
}
.account-view a.button span span {
     padding:0 24px;
     font-size:20px;
     font-weight:700;
     height:45px!important;
     line-height:45px!important;
     font-family:'Spinnaker', sans-serif;
     display:inline-block;
}
.account-view a.button span {
     background-color:#C1272D;
     color:#fff;
     border-radius:5px;
}
.account-view a.button:hover span {
     background-color:#2652BF !important;
}
.po-invc .pager .amount {
     padding-top:0;
}
.po-invc #form_filter_user_data input, .po-invc #form_filter_user_data button {
     margin-left:10px;
}
.po-invc .buttons-set p.back-link {
     display:none;
}
@media only screen and (max-width:767px) {
     .po-invc .my-account .pager p.amount, .po-invc .filter_order_search_label, .po-invc .my-account > p {
         font-size:12px !important;
    }
     .po-invc #form_filter_user_data input {
         width:100px;
    }
     .po-invc .my-account .pager p.amount {
         padding-left:5px;
         float:left;
    }
     .po-invc button.button span {
         font-size:16px;
    }
}
@media only screen and (max-width:767px) {
     .login-module {
         margin-top:50px !important;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .login-module {
         width:40%;
    }
}
@media only screen and (max-width:767px) {
     button[title='Change Email'] {
         margin-bottom:50px !important;
    }
}
/*Transaction details*/
.page-title.module-trans-details-status h1 span {
     font-size:36px;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie {
     padding:10px;
     background-color:#C1272D;
     border-radius:5px;
     cursor:pointer;
     color:#fff;
     border:none;
}
.module-trans-details-actions input.form-control {
     height:30px;
     width:70px;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .xm-grid-account .grid-container-wrapper {
         overflow:unset;
    }
}
@media only screen and (max-width:959px) {
     .page-title.module-trans-details-status h1 span {
         font-size:32px !important;
    }
     .ReactTable .rt-thead.-filters input{
         max-width:80px;
    }
     .ReactTable .rt-thead.-filters select{
         width:80%;
         height:26px;
    }
     .ReactTable a, .ReactTable div, .ReactTable p, .ReactTable span{
         font-size:11px !important;
    }
     /*Display block the links in the last column*/
     #order-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child a{
         display:block;
         width:90px;
         text-align:center;
    }
     #order-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child span {
         display:none;
    }
}
.module-login-form.registered-users .form-list li {
     margin-bottom:15px;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .new-users.grid12-6 {
         width:96%;
         margin-bottom:40px;
    }
}
/*Register page*/
#usre_rego ul.form-list > li {
     margin-bottom:20px;
}
#usre_rego ul.form-list > li > *{
     margin-right:15px;
}
#usre_rego .buttons-set .back-link {
     float:right;
}
#usre_rego .buttons-set .button {
     float:left;
}
.account-view .due-input, .module-trans-details-actions .form-control {
     font-size:16px;
}
#transhistory-table .rt-td, #order-table .rt-td{
     text-align:center;
}
.module-trans-details-misc .item-options {
     display:flex;
}
.module-trans-details-misc .item-options > *{
     margin:auto 0;
}
.module-order-details-misc #order_details_invoice_no a {
     border-bottom:1px solid #000;
}
@media print {
     .ord-details .grid-column-wrapper {
         width:98%;
    }
     .module-trans-details-listing {
         max-width:95%;
    }
     .module-order-details-listing {
         max-width:95%;
    }
     @page {
         size:landscape 
    }
}
/*Hide bullet from the PO screen*/
.po-invc #cart_contents li.fields {
     list-style:none;
}
/*PO Complete screen*/
.po-invc-complt #my-invoices-table colgroup col {
     width:auto !important;
}
.po-invc-complt #my-invoices-table tbody tr td:nth-child(3) {
     text-align:center;
}
/**/
.cart .sp-methods .cart .tbc-price {
     display:block;
     margin-bottom:20px;
     font-style:italic;
     font-size:14px;
     color:#c1272d;
}
#for-8130-addr em {
     font-style:normal;
     color:#C1272D ;
}
.new-8130-field {
     margin-top:20px;
}
#eligible-8130 {
     width:90px;
}
.cart-action-buttons button {
     margin:20px 10px;
}
@media only screen and (max-width:767px) {
     #shopping-cart-table {
         overflow:hidden;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .cart-action-col {
         overflow:hidden;
    }
}
@media only screen and (min-width:1200px){
     .cart-summary-col, .tax-text {
         width:30%;
    }
     .update-empty-cart {
         width:66%;
    }
}
.data-table:not(.cart-table) img {
     width:50% !important;
     min-width:inherit;
}
.payment-input > * {
     margin-top:15px;
}
.preauth-text, .disclaimer-text{
     font-weight:600;
}
.data-table tr.last.total-charge.grant-total-amount {
     display:none;
}
@media only screen and (min-width:1200px){
     .preauth-text, .disclaimer-text {
         width:50%;
    }
     .disclaimer-text {
         float:right;
    }
}
/*T&C*/
.terms_conditions label a {
     border-bottom:1px solid #000;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .opc-main-container.onepage-checkout-main-container, .in-sidebar.opc-progress-container {
         width:98%;
    }
}
@media only screen and (max-width:767px) {
     .data-table tfoot td {
         display:inline-block !important;
         width:30% !important;
         min-width:100px;
         max-width:140px;
         margin-right:10px;
         font-weight:700;
    }
}
/*Show all pages in the pagination on category pages*/
@media only screen and (max-width:479px) {
     .pager .pages li {
         display:inherit;
    }
}
@media only screen and (min-width:1260px) {
     .hide-above-1259 {
         display:none;
    }
}
@media only screen and (max-width:1259px) {
     .hide-below-1259 {
         display:none;
    }
}
@media only screen and (min-width:768px) and (max-width:1259px) {
     .desktop-top-header .cart-column {
         margin-top:2% !important;
    }
}
.desktop-top-header .cart-column .feature-icon-hover > a:hover span.fa:before {
     color:#000 !important;
}
/*Switch Customer*/
.userSwitch a {
     text-indent:-9999px;
     position:absolute;
}
 .userSwitch a::after {
     content:"(Switch Bill-to Company)";
     text-indent:0;
     float:left;
}
@media only screen and (max-width:767px) {
     .top-header-container {
         margin-bottom:0;
    }
     .top-header-container .item.item-switch-customer {
         float:left;
    }
     .top-header-container .item.item-switch-customer span, .top-header-container .item.item-switch-customer a:after {
         font-size:12px;
         line-height:24px;
    }
}
